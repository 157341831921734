import { createSlice } from "@reduxjs/toolkit";
import {
  getCMSCreateUpdate, getCMSFeatures, getCMSListData, getCMSStatusUpdate, getPrioityStatus,
} from "../../../utility/thunks";
import {  
  ItemLimitsInPage,
} from "../../../commons";

export const initialFilterState = {
  searchTerm: "",
  responseSearchTerm: "",
  filterDateFrom: null,
  filterDateTo: null,
  itemLimitsInPage: ItemLimitsInPage[0],
  currentPage: 1,
  itemList: "",
};

const initialState = {
  loading: false,
  createUpdateCMS: {
    loading: false,
    id:[],
    parentCategory: [],
    errorNo: 0,   
    errorMessage: "",
  },
  CMSFeatures: {
    loading: false,
    BetFeatures: [],
    errorNo: 0,   
    errorMessage: "",
  },
  CMSListData:{
    loading: false,
    cmsData: [],
    errorMessage: "",
  },
  CMSStatusUpdate:{
    loading: false,
  },
  prioityStatus:{
    loading: false,
    CMSPriority: [],
    errorMessage: "",
  },
};

export const cmsSlice = createSlice({
  name: "cms",
  initialState,
  reducers: {
    updateSelectedItemIds: (state, action) => {
      state[action.payload.ItemListName].selectedItemIds =
        action.payload.selectedItemIds;
    },
    setMarketCreationFilters: (state, action) => {
      state[action.payload.ItemListName].itemLimitsInPage = action.payload.itemLimitsInPage;
      state[action.payload.ItemListName].searchTerm = action.payload.searchTerm;      
      state[action.payload.ItemListName].currentPage =  action.payload.currentPage;
      state[action.payload.ItemListName].sortBy =  action.payload.sortBy;
      state[action.payload.ItemListName].filterDateFrom =  action.payload.filterDateFrom;
      state[action.payload.ItemListName].filterDateTo =  action.payload.filterDateTo;
    },
  },
  extraReducers: (builder) => {
    builder 
      .addCase(getCMSCreateUpdate.pending, (state) => {
        state.createUpdateCMS.loading = true;
      })
      .addCase(getCMSCreateUpdate.fulfilled, (state, action) => {
        state.createUpdateCMS.loading = false;
        state.createUpdateCMS=action.payload;
      })
      .addCase(getCMSCreateUpdate.rejected, (state) => {
        state.createUpdateCMS.loading = false;
      })
      .addCase(getCMSFeatures.pending, (state) => {
        state.CMSFeatures.loading = true;
      })
      .addCase(getCMSFeatures.fulfilled, (state, action) => {
        state.CMSFeatures.loading = false;
        state.CMSFeatures=action.payload;
      })
      .addCase(getCMSFeatures.rejected, (state) => {
        state.CMSFeatures.loading = false;
      })
      .addCase(getCMSListData.pending, (state) => {
        state.CMSListData.loading = true;
      })
      .addCase(getCMSListData.fulfilled, (state, action) => {
        state.CMSListData.loading = false;
        state.CMSListData=action.payload;
      })
      .addCase(getCMSListData.rejected, (state) => {
        state.CMSListData.loading = false;
      })
      .addCase(getCMSStatusUpdate.pending, (state) => {
        state.loading = true;
      })
      .addCase(getCMSStatusUpdate.fulfilled, (state, action) => {
          state.loading = false;          
          state.CMSListData.errorNo = action.payload.errorNo;
          state.CMSListData.errorMessage = action.payload.errorMessage;
      })
      .addCase(getCMSStatusUpdate.rejected, (state) => {
        state.CMSListData = false;
      })
      .addCase(getPrioityStatus.pending, (state) => {
        state.prioityStatus.loading = true;
      })
      .addCase(getPrioityStatus.fulfilled, (state, action) => {
        state.prioityStatus.loading = false;
        state.prioityStatus=action.payload;
      })
      .addCase(getPrioityStatus.rejected, (state) => {
        state.prioityStatus.loading = false;
      })
      ;
  },
});
export const { updateSelectedItemIds, setMarketCreationFilters } =
cmsSlice.actions;
export default cmsSlice.reducer;
